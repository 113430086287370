const appConfig = {
  apiPrefix: '/api',
  authenticatedEntryPath: '/account/ads',
  unAuthenticatedEntryPath: '/',
  tourPath: '/discover',
  locale: 'en',
  enableMock: true,
};

export default appConfig;
