import { gql } from '@apollo/client';

export const GET_PROFILES_AGGREGATE = gql`
  query ($where: profile_bool_exp) @cached(ttl: 3600) {
    profile_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PROFILES = gql`
  query ($offset: Int!, $limit: Int!, $where: profile_bool_exp) @cached(ttl: 3600) {
    profile(
      limit: $limit
      offset: $offset
      where: $where
      order_by: [{ position: desc }, { uid: asc }]
    ) {
      uid
      code
      age
      name
      avatar
      last_seen
      location_now
      location_amenities
      location_options
      location_service
      position
      limited
      disabled
      deleted
      active

      prices {
        uid
        value
        label
      }

      reference {
        trust
        critial_certified
        critial_safe_contact
        critial_reliable
      }
    }
  }
`;

export const GET_OPTIONS = gql`
  query getOptions @cached(ttl: 28800) {
    attributes {
      uid
      label
    }
    services {
      uid
      label
    }
  }
`;
