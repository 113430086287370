import useCallbackRef from './useCallbackRef';
import useColorLevel from './useColorLevel';
import useControllableState from './useControllableState';
import useDidUpdate from './useDidUpdate';
import useMergeRef from './useMergeRef';
import useRootClose from './useRootClose';
import useTimeout from './useTimeout';
import useUncertainRef from './useUncertainRef';
import useUniqueId from './useUniqueId';
import useWindowSize from './useWindowSize';

const hooks = {
  useCallbackRef,
  useColorLevel,
  useControllableState,
  useDidUpdate,
  useMergeRef,
  useRootClose,
  useTimeout,
  useUncertainRef,
  useUniqueId,
  useWindowSize,
};

export default hooks;
