import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tag = (props) => {
  const {
    className,
    children,
    prefix,
    suffix,
    prefixClass,
    suffixClass,
    handleClick,
    rest,
  } = props;

  return (
    <button
      type="button"
      className={classNames('tag', className)}
      onClick={handleClick}
      {...rest}
    >
      {prefix && typeof prefix === 'boolean' && (
        <span className={classNames('tag-affix tag-prefix', prefixClass)} />
      )}
      {typeof prefix === 'object' && prefix}
      {children}
      {suffix && typeof suffix === 'boolean' && (
        <span className={classNames('tag-affix tag-suffix', suffixClass)} />
      )}
      {typeof suffix === 'object' && suffix}
    </button>
  );
};

Tag.defaultProps = {
  prefix: false,
  suffix: false,
};

Tag.propTypes = {
  prefix: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  prefixClass: PropTypes.string,
};

export default Tag;
