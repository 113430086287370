import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { stringify } from 'utils/text';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { GET_PROFILES, GET_PROFILES_AGGREGATE, GET_OPTIONS } from './catalog.gql';
import { locations } from 'constants/location.constant';

const CatalogContext = React.createContext();

export function useCatalog() {
  return useContext(CatalogContext);
}

export default function CatalogProvider({ children }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const ptc_location = localStorage.getItem('ptc_location');
  const [location, setLocation] = useState(
    ptc_location ? JSON.parse(ptc_location) : null
  );
  const [locationLoading, setLoacationLoading] = useState(false);
  const localEnabledLocation = localStorage.getItem('ptc_enabled_location');
  const [enabledLocation, setEnabledLocation] = useState(
    localEnabledLocation ? JSON.parse(localEnabledLocation) : null
  );

  const pushLocationPath = () => {
    const { region_code, city } = location;
    const param = `/${city.replace(' ', '-')}-${region_code}`.toLowerCase();
    const regex = /[^a-zA-Z0-9-]/g;
    const value = param.normalize('NFD').replace(regex, '');
    let path = 'acompanhantes';
    if (pathname.includes('homens')) path = 'homens';
    if (pathname.includes('trans')) path = 'trans';
    navigate(`/${path}/${value}`);
  };

  useEffect(() => {
    const isPath = pathname === '/' || pathname === '/homens' || pathname === '/trans';
    if (enabledLocation && isPath) {
      pushLocationPath();
    }
  }, [enabledLocation, pathname]);

  const configIp = async () => {
    const ptc_location = localStorage.getItem('ptc_location');
    if (ptc_location) return;

    try {
      setLoacationLoading(true);
      const response = await fetch('https://api.ipify.org?format=json');
      const { ip } = await response.json();

      if (ip) {
        const response = await fetch(`https://ipapi.co/${ip}/json/`);
        const data = await response.json();

        const loc = { ip, ...data };
        localStorage.setItem('ptc_location', stringify(loc));
        setLocation(loc);
        setLoacationLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoacationLoading(false);
    }
  };

  useEffect(() => {
    configIp();
  }, []);

  const { data: optionsData } = useQuery(GET_OPTIONS);
  const attributes = optionsData?.attributes || [];
  const services = optionsData?.services || [];

  // catalog
  const limit = 20;
  const [offset, setOffset] = useState(0);
  const [filterDialog, setFilterDialog] = useState(false);
  const [profiles, setProfiles] = useState(null);

  // location
  const locationPath = pathname.split('/').pop();
  const locationCity = locations
    ?.find((loc) => loc?.options?.find((opt) => opt?.value === locationPath))
    ?.options.find((opt) => opt?.value === locationPath)?.label;
  const locationVariables = {
    location_now: {
      _like: `%${locationCity || ''}%`,
    },
  };
  const positionVariables = {
    position: {
      _is_null: false,
    },
    active: {
      _eq: true,
    },
  };

  // gender
  const gender = pathname.includes('homens') ? 'Home' : 'Mulher';
  const genderVariables = {
    attributes: {
      attribute: { label: { _eq: 'Gênero' } },
      value: pathname.includes('trans') ? { _like: '%Tra%' } : { _like: `%${gender}%` },
    },
  };

  const [filter, setFilter] = useState({});
  const where = {
    ...filter,
    ...genderVariables,
    ...locationVariables,
    ...positionVariables,
  };

  const [getProfiles, { data, loading, error }] = useLazyQuery(GET_PROFILES, {
    variables: {
      limit,
      offset,
      where,
    },
  });
  console.log({ data, loading, error });

  const { data: totalData } = useQuery(GET_PROFILES_AGGREGATE, {
    variables: {
      where,
    },
  });
  const total = totalData?.profile_aggregate?.aggregate?.count || 0;

  useEffect(() => {
    setProfiles(null);
    getProfiles({
      variables: {
        offset: 0,
        limit,
      },
    });
  }, [pathname]);

  useEffect(() => {
    if (data) {
      setProfiles((arr) => {
        if (!arr) {
          setOffset(limit);
          return data.profile;
        }

        setOffset((offset) => offset + limit);
        return [...arr, ...data.profile];
      });
    }
  }, [data]);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      if (offset + limit > total) return;

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          const o = offset + limit;
          setOffset(o);
          getProfiles({
            variables: {
              offset: offset + limit,
              limit,
            },
          });
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, offset, total, profiles]
  );

  const value = {
    total,
    profiles,
    error,
    offset,
    where,
    filter,
    filterDialog,
    setProfiles,
    getProfiles,
    setFilterDialog,
    lastElementRef,
    setFilter,
    setOffset,
    loading,
    location,
    locationLoading,
    setLocation,
    configIp,
    pushLocationPath,
    enabledLocation,
    setEnabledLocation,
    attributes,
    services,
  };

  return <CatalogContext.Provider value={value}>{children}</CatalogContext.Provider>;
}
